<template>
  <v-form
    ref="formLogin"
  >
    <v-container class="pa-5 pt-4">
      <v-row>
        <!-- N Ficha -->
        <v-col
          cols="12"
        >
          <v-text-field
            v-model="username"
            label="Usuário"
            :rules="[rules.noCoding, rules.exists]"
            outlined
            required
            @keyup.enter="handleSubmit()"
          />
          <v-text-field
            v-model="password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            label="Senha"
            :rules="[rules.noCoding, rules.exists]"
            :type="show ? 'text' : 'password'"
            outlined
            required
            @click:append="show = !show"
            @keyup.enter="handleSubmit()"
          />

          <v-checkbox
            v-model="rememberUser"
            label="Lembrar-me"
          />
          <v-btn
            color="primary"
            primary
            class="float-right"
            @click.prevent="handleSubmit()"
          >
            Entrar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="snackbar"
      multi-line
      color="error"
      dark
      :timeout="timeout"
    >
      <h3 class="text-center">
        <b>{{ loginError }}</b>
      </h3>
    </v-snackbar>
  </v-form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import rules from '../../services/rules'

  export default {
    data: () => ({
      submitted: false,
      username: '',
      password: '',
      rememberUser: '',
      show: false,
      rules: rules,
    }),
    computed: {
      ...mapState('account', ['status', 'loginError', 'hadError', 'timeout']),
      snackbar: {
        get: function () {
          return this.hadError
        },
        set: function (newValue) {
          this.snackbarError(newValue)
        },
      },
    },
    created () {
      // reset login status
      this.logout()
    },
    mounted () {
      this.remindUser()
    },
    methods: {
      ...mapActions('account', ['login', 'logout', 'snackbarError']),
      async handleSubmit () {
        this.submitted = true
        const { username, password, rememberUser } = this
        if (username && password) {
          await this.login({ username, password, rememberUser })
            .catch((e) => {
              console.log(e)
              this.$alert('error', 'Erro ao tentar logar')
            })
        }
      },
      remindUser () {
        const user = JSON.parse(localStorage.getItem('rememberUser'))
        if (!user) { return }
        if (user.username && user.password) {
          this.rememberUser = 1
          this.username = user.username
          this.password = user.password
        }
      },
    },
  }
</script>
